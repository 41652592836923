/*================================================================
[Table of contents]
==================================================================*/
/*
	1. Include bootstrap 5 beta default
	2. General styles and resets
	3. Header / #header
	4. Buttons / .btn
	5. Backgrounds colors
	6. Parallax /.parallax
	7. Navigation /.navbar-nav
	8. Banner /.banner
	9. Services /.services
	10. About us /.about
	11. Counter /.count-block
	12. our work /.portfolio
	13. Carousel /.owl-carousel
	14. Home blog /.newsnblog 
	15. Price table /.price-table 
	16. Contact /.contact 
	17. Pages
	18.Footer /#footer
==================================================================*/

// ==================================================================
// SCSS IMPORTS
// ==================================================================
//import google fonts
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400&display=swap");

// Bootstrap
@import "../../../node_modules/bootstrap/scss/functions";

// Change variables here
@import "seetings/main";

// Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap";

/*================================================================
1. General styles and resets
==================================================================*/
// ---  BASE  ---
@import "base/base";

// ---  PRELOADER ---
@import "components/preloader";
@import "components/pagination";
@import "components/content-search";
@import "components/recent-article";
@import "components/social-icons";

// ---  HEADER ---
/*================================================================
3. Header /#header
==================================================================*/
@import "layout/header";

// ---  SIDEBAR  ---
/*================================================================
3. Sidebar /#sidebar
==================================================================*/
@import "layout/sidebar";

// ---  COMPONENTS  ---
/*================================================================
3. Swiper Slide /.swiper-slide
==================================================================*/
@import "components/swiper-slide";

/*================================================================
3. MasonryJs /.masonry 
==================================================================*/
@import "components/masonry";
/*================================================================
3. buttons /.btn 
==================================================================*/
@import "components/buttons";

/*================================================================
3. Custom form /.custom-form 
==================================================================*/
@import "components/custom-form";

/*================================================================
3. Accordion Style /.accordion
==================================================================*/
@import "components/accordion";

/*================================================================
3. Hero showcase /.hero-showcase
==================================================================*/
@import "components/hero-showcase";

/*================================================================
3. Canvas Sidebar /.canvas-sidebar
==================================================================*/

@import "components/progress";
@import "components/navbar";
@import "components/card";
@import "components/perfact-scrollbar";
@import "components/timelines";
@import "components/actions-bar";
@import "components/ql-editor";
@import "components/croppie";
@import "components/datatable";
@import "components/cusom-tab";

// // ---  Page ---
/*================================================================
18.Page /#page
==================================================================*/
@import "page/500";
@import "page/404";
@import "page/coming-soon";
@import "page/login-register";

// // ---  FOOTER ---
/*================================================================
18.Footer /#footer
==================================================================*/
@import "layout/footer";

.rdp {
  --rdp-cell-size: 36px;
  --rdp-accent-color: var(--flm-primary);
  --rdp-background-color: rgba(var(--flm-primary), 0.1);
  --rdp-accent-color-dark: darken(var(--flm-primary), 5%);
  --rdp-background-color-dark: darken(var(--flm-primary), 5%);
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
}

//aria-labelledby="react-media-library-modal"
.modal-backdrop {
  z-index: 9999;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.75);
}
div[aria-labelledby="react-media-library-modal"] {
  z-index: 9999;
  .modal-dialog {
    box-shadow: 0 16px 16px -10px rgb(34 47 62 / 15%), 0 0 40px 1px rgb(34 47 62 / 15%);
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    margin: 0;
    .modal-content {
      border-radius: 3px;
      border: 1px solid $border-color;
      height: 100%;
      .tab-content {
        height: 100%;
      }
      .modal-header {
        padding-bottom: 0;
        border-bottom: 0;
        .close {
          background-color: transparent;
          border: none;
          opacity: 1;
          text-shadow: none;
          @include font-size(1.5rem);
          .sr-only {
            display: none;
          }
        }
      }
      .modal-body {
        position: static;
        padding-top: 0;
        .pt-3 {
          padding-top: 0 !important;
        }
        .alert-secondary {
          background-color: rgba($primary, 0.1);
          border: 1px solid rgba($primary, 0.12);
        }
        .nav-tabs {
          position: absolute;
          left: 15px;
          top: 32px;
          border: 0;
          .nav-link {
            border: 0;
            background-color: transparent;
            @include font-size(0.8375rem);
            font-weight: 600;
            padding: 0;
            & + .nav-link {
              margin-left: 10px;
            }
          }
        }
        .tab-pane {
          h3 {
            @include font-size(1rem);
          }
          > ul > .list-group-item {
            .badge {
              font-weight: 500;
              color: inherit;
            }
          }
          .row {
            // display: grid;
            // grid-gap: 5px;
            // grid-template-columns: repeat(6, 100px);
            // grid-template-rows: 100px 100px 100px;
            // grid-auto-flow: column;
            margin: -0.25rem;
            flex-wrap: wrap;

            > .mb-3 {
              padding: 0.25rem;
              width: 100px;
              flex: 0 0 100px;
            }
          }
          .text-right {
            position: fixed;
            right: 15px;
            bottom: 15px;
            width: calc(100% - 30px) !important;
            text-align: right;
          }
        }

        .card {
          padding: 4px;
          border-radius: 1px;
          border: 1px solid transparent;
          background-color: transparent;
          .card-img-top {
            border: 0;
            border-radius: 0;
            box-shadow: 0 0 2px rgba($black, 0.24);
            height: auto !important;
            width: auto !important;
            max-height: 100px;
          }
          .card-body {
            display: none;
          }
          .list-group {
            > .list-group-item:not(:nth-child(1)) {
              display: none;
            }
            > .list-group-item {
              padding: 5px 0 0;
              border: none;
              font-size: 0.75rem;
              background-color: transparent;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          &:hover {
            border-color: rgba($primary, 0.2);
            background-color: rgba($primary, 0.0875);
            .card-img-top {
              box-shadow: none;
            }
          }
          &.bg-primary {
            border-color: rgba($primary, 0.5);
            background-color: rgba($primary, 0.0875) !important;
            .card-img-top {
              box-shadow: none;
            }
          }
        }
      }
    }
    .pagination .page-item .page-link {
      font-weight: 500;
      @include font-size(1.125rem);
    }
    .pagination .page-item.active .page-link {
      box-shadow: none;
      background-color: $primary;
    }
  }
}

.sr-only {
  display: none;
}

.external-link {
  @include font-size(0.75rem);
  font-weight: 500;
  letter-spacing: 0.2px;
  display: inline-block;
}

//package-departure
.package-departure {
  .date-list {
    ul {
      display: flex;
      list-style: none;
      padding: 0;
      flex-wrap: wrap;
      gap: 5px;
      > li {
        @include font-size(0.9375rem);
        font-weight: 500;
        color: $text-muted;
        span {
          font-size: 13px;
          background-color: rgba($primary, 0.1);
          color: $primary;
          padding: 3px 10px;
          border-radius: 3px;
          font-weight: 600;
        }
      }
    }
  }
}

//data-table
.data-table {
  .btn-sm {
    padding: 3px 12px;
    border: 0;
    border-radius: 50rem;
    font-weight: 600;
    white-space: nowrap;
    @include font-size(0.75rem);
    i {
      line-height: 0;
      vertical-align: middle;
      margin-right: 5px;
    }

    &.btn-danger {
      background-color: rgba($danger, 0.12);
      color: $danger;
      &:hover {
        background-color: $danger;
        color: $white;
      }
    }
    &.btn-primary {
      background-color: rgba($primary, 0.12);
      color: $primary;
      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
    &.btn-warning {
      background-color: rgba($warning, 0.12);
      color: $warning;
      &:hover {
        background-color: $warning;
        color: $white;
      }
    }
  }
}

//ant-tabs-nav
.common-module {
  .ant-tabs-tab {
    font-weight: 600;
    @include font-size(0.9375rem);
    &:hover {
      color: $primary;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $primary;
  }
  .ant-tabs-ink-bar {
    background-color: $primary;
  }
}

.btn-icon {
  @include font-size(2rem);
  line-height: 0;
  margin-right: 15px;
  color: $info;
}

//itineraries
.itineraries {
  .ant-collapse {
    & + .ant-collapse {
    }
    .ant-collapse-header {
      .ant-collapse-expand-icon {
        .ant-collapse-arrow {
          height: 24px;
          width: 24px;
          color: $primary;
          border: 2px solid $primary;
          border-radius: 50rem;
          @include font-size(1.125rem);
          line-height: 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    &.ant-collapse-item-active {
    }
  }
}

//loader-overly
.relative {
  position: relative;
}
.loader-overly {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($white, 0.95);
  z-index: 99;
  .ant-spin {
    color: $primary;
  }
  .ant-spin-dot-item {
    background-color: $primary;
  }
}

//flamingo-file-manager-body
.flamingo-file-manager {
  //z-index: 1201;

  .ant-modal {
    max-width: 1240px;
  }
  .ant-modal-body {
    border-radius: 4px;
    padding: 15px;
  }
  .ant-modal-close {
    display: none;
  }
  .flamingo-file-manager-body {
    border: 1px solid $border-color;
    border-radius: 4px;
    .manager-toolbar {
      border-bottom: 1px solid $border-color;
      padding: 0.75rem 1.25rem;
      position: relative;
      display: flex;
      align-items: center;
      .btn-upload {
        @include font-size(0.8375rem);
        font-weight: 500;
        text-transform: uppercase;
        text-decoration: none;
        color: $headings-color;
        i {
          display: inline-block;
          margin-right: 4px;
          height: 20px;
          vertical-align: middle;
          width: 20px;
          @include font-size(1rem);
        }
      }
      .manager-toolbar-overlay {
        position: absolute;
        inset: 0;
        background-color: $white;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 24px;
        padding-right: 24px;
        > ul {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          padding-left: 0;
          > li {
            list-style: none;
            & + li {
              padding-left: 10px;
            }
            &:nth-child(2) {
              margin-left: auto;
            }
          }
        }
        .btn {
          border-radius: 4px;
          border: 0;
          color: $headings-color;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 13px;
          padding: 6px 20px;
          i {
            background-color: #fff;
            border-radius: 50rem;
            height: 16px;
            width: 16px;
            display: inline-flex;
            line-height: 0;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            margin-right: 4px;
          }
          &.btn-danger {
            background-color: rgba($danger, 0.1);

            i {
              //font-size: 12px;
              background-color: transparent;
              color: $danger;
            }

            &:hover {
              color: $danger;
            }
          }
          &.btn-success {
            background-color: rgba($danger, 0.1);
            i {
              background-color: $success;
              color: $white;
            }
          }

          &.btn-warning {
            background-color: rgba($warning, 0.1);
            i {
              background-color: $warning;
              color: $white;
            }
            &:hover {
              color: $warning;
            }
          }

          &.btn-primary {
            background-color: rgba($primary, 0.1);
            i {
              background-color: $primary;
              color: $white;
            }
            &:hover {
              color: $primary;
            }
          }
        }
      }
      .input-media-search{
        margin-left: auto;
        max-width: 250px;
        
      }
    }

    .flamingo-file-manager-container {
      display: flex;
    }

    .media-card-view {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      overflow: hidden;
      padding: 15px;
      position: relative;
      width: 100%;
      .loading {
        position: absolute;
        inset: 0;
        background-color: rgba($white, 0.85);
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      > ul:not(.ant-pagination) {
        margin: 0;
        padding: 0;
        > li {
          float: left;
          list-style: none;
          .item {
            height: 124px;
            width: 124px;
            cursor: pointer;
            margin: 5px;
            text-align: center;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px solid transparent;
            position: relative;
            .buttons {
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              display: flex;
              align-items: flex-end;
              justify-content: center;
              padding: 8px;
              background-color: rgba($white, 1);
              transform: translateY(100%);
              opacity: 0;
              button {
                background-color: white;
                padding: 0;
                height: 24px;
                width: 24px;
                padding: 2px;
                display: inline-flex;
                text-align: center;
                align-items: center;
                justify-content: center;
                line-height: 0;
                border-radius: 50rem;
                box-shadow: none;
                border: none;
                outline: none;
                & + button {
                  margin-left: 5px;
                }
                &.edit-button {
                  color: $primary;
                }
                &.delete-button {
                  color: $danger;
                }
              }
            }
            .inner-wrap {
              padding: 13px 0 10px;
              overflow: hidden;

              .icon {
                display: inline-block;
                height: 72px;
                width: 72px;
                position: relative;
                border-radius: 4px;
                overflow: hidden;
                > img {
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  border-radius: 4px;
                  object-fit: contain;
                  inset: 0;
                }
              }
              .name {
                overflow: hidden;
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 12px;
                display: block;
              }
            }
            &:hover {
              .buttons {
                transition: 0.3s ease all;
                transform: translateY(0);
                opacity: 1;
              }
            }
            &:hover,
            &.is-selected {
              background-color: rgba($primary, 0.1);
              border-color: rgba($primary, 0.1);
            }
          }
          .edit-button,
          .delete-button {
            display: none;
            // position: absolute;
            // top: 5px;
            // right: 5px;
          }

          &:hover .edit-button,
          &:hover .delete-button {
            display: block;
          }
        }
      }
      .ant-pagination {
        text-align: center;
        .ant-pagination-item {
          //border-color: $border-color;
        }
      }
    }
  }
  .ant-modal-footer {
    display: none;
  }
}

//action-bar
.action-bar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  .action-bar-left {
    > span {
      display: inline-block;
      margin-bottom: 10px;
      font-size: 12px;
      text-decoration: none;
      font-weight: 500;
      cursor: pointer;
      color: $primary;
    }
    .action-bar-title {
      display: flex;
      flex-wrap: wrap;
      .btn-discard {
        height: 38px;
        width: 38px;
        background-color: $white;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        @include font-size(2rem);
        border-radius: 50rem;
        padding: 0;
        margin: 0;
        line-height: 0;
        border: 1px solid $border-color;
        color: $primary;
        i {
          line-height: 0;
        }
        &:hover {
          border-color: $primary;
        }
      }
      h1 {
        margin-bottom: 0;
        padding-top: 5px;
        padding-left: 10px;
        @include font-size(1.5rem);
      }
      a {
        @include font-size(1.75rem);
        display: inline-block;
        line-height: 1.25;
        margin-left: 5px;
      }
    }
  }
  .action-bar-right {
    margin-left: auto;
    .status {
      background-color: $white;
      padding: 10px;
      border-radius: 4px;
      border: 1px solid $border-color;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      .icon {
        height: 50px;
        width: 50px;
      }
      span {
        margin-left: 0;
      }
    }
  }
}

//fis-nav-tabs
.fis-nav-tabs {
  .ant-menu {
    border-bottom: 0;
    background-color: transparent;
    &.ant-menu-horizontal {
      line-height: 36px;
    }
    .ant-menu-title-content {
      color: $body-color;
    }
    > li:after {
      // .ant-menu-title-content{
      //   &:before{
      //     max-width: 40px;
      //     right: auto;
      //     left: 0;
      //   }
      // }
      width: 40px;
      right: auto;
    }
    > li:first-child {
      padding-left: 0;
      &:after {
        left: 0;
      }
    }
    > li.ant-menu-item-selected {
      .ant-menu-title-content {
        color: $headings-color;
        font-weight: 600;
      }
      &:after {
        border-bottom-color: $primary;
      }
    }
    > li:hover {
      .ant-menu-title-content {
        color: $primary;
      }
      &:after {
        border-bottom-color: $primary;
      }
    }
  }
}

//borderContainer
.module-container {
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  display: flex;
  margin-left: -$grid-gutter-width;
  margin-right: -$grid-gutter-width;
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
  > div {
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
  }
  .module-container-left {
    max-width: 200px;
    flex: 0 0 200px;
  }
  .module-container-right {
    border-left: 1px solid $border-color;
    max-width: calc(100% - 200px);
    flex: 0 0 calc(100% - 200px);
    padding-left: $grid-gutter-width;
  }
  .ant-form-item {
    //max-width: 500px;
  }

  .ant-form-large .ant-form-item-label > label {
    height: 32px;
  }

  .ant-form-item-label > label {
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0;
    color: darken($primary, 20%);
  }

  h3 {
    @include font-size(1.125rem);
  }
}

//ant-anchor-wrapper fis-anchor
.ant-anchor-wrapper.fis-anchor {
  margin-left: -24px;
  padding-left: 0;
  > .ant-anchor {
    width: 100%;
    color: $body-color;
    font-family: $font-family-base;
    margin-left: 0;
    padding-left: 0;
    > .ant-anchor-ink {
      display: none;
    }
    > .ant-anchor-link {
      padding: 10px 24px;
      > a {
        text-decoration: none;
        color: $body-color;
        font-size: 14px;
      }

      &.ant-anchor-link-active {
        background-color: $body-bg;
        color: $headings-color;
        border-right: 2px solid $primary;
      }
    }
  }
}

//inner-action-bar
.inner-action-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  &.header {
    padding-bottom: $grid-gutter-width;
  }
  &.footer {
    padding-top: $grid-gutter-width;
  }
  .left-content {
    display: flex;
    align-items: center;
    gap: 12px;
    h2 {
      @include font-size(1.125rem);
      margin-bottom: 0;
    }
    .icon {
      height: 30px;
      width: 30px;
    }
  }
  .right-content {
    margin-left: auto;
    .btn-group {
      display: flex;
      align-items: center;
      gap: 5px;
      .btn {
        font-weight: 500;
        font-size: 13px;
        border: 0;
        i {
          display: inline-block;
          vertical-align: middle;
          margin-right: 6px;
          line-height: 0;
          @include font-size(1rem);
        }
        &:disabled {
          background-color: $body-bg;
        }
      }
    }
  }
}

//.thumbinal-image figure
.thumbinal-image figure {
  margin-bottom: 0;
}

//media-display-slot
.media-display-slot {
  ul {
    display: flex;
    gap: 12px;
    padding-left: 0;
    margin-bottom: 0;
    flex-wrap: wrap;
    > li {
      list-style: none;
      padding: 0;
      figure {
        display: block;
        position: relative;
        margin-bottom: 0;
        display: block;
        border: 1px solid $border-color;
        border-radius: 4px;
        padding: 6px;
        .image-slot {
          display: inline-block;
          height: 120px;
          width: 120px;
          position: relative;
          border-radius: 4px;
          overflow: hidden;
          > img {
            position: absolute;
            height: 100%;
            width: 100%;
            border-radius: 4px;
            object-fit: contain;
            inset: 0;
          }
        }
        figcaption {
          position: absolute;
          inset: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba($white, 0.9);
          border-radius: 4px;
          opacity: 0;
          transform: translateY(-100%);
          visibility: hidden;
          .btn-trash {
            border: 0;
            padding: 0;
            outline: none;
            box-shadow: none;
            line-height: 0;
            background-color: transparent;
            color: $danger;
            font-size: 20px;
          }
        }
        &:hover {
          figcaption {
            opacity: 1;
            transform: translateY(0);
            visibility: visible;
            transition: 0.3s ease;
          }
          &:focus{
            figcaption{
              display: none;
            }

          }
        }
      }
      &.btn-media-add {
        button[type="button"] {
          height: 140px;
          width: 134px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          border-radius: 4px;
          background-color: transparent;
          border: 2px dashed $border-color;
          color: $text-muted;
          > span {
            @include font-size(0.8375rem);
            font-weight: 500;
          }
          > span:before {
            content: "\f4d1";
            font-family: "bootstrap-icons";
            @include font-size(1.5rem);
            display: block;
            font-weight: normal;
          }

          &:hover {
            border-color: $primary;
            color: $primary;
          }
        }
      }
    }
  }
}

//status
.status {
  .icon {
    display: inline-block;
    > svg {
      height: 100%;
      width: 100%;
    }
  }

  span {
    display: inline-block;
    margin-left: 10px;
    font-weight: 600;
    color: $text-muted;
    font-size: 14px;
  }
}

//tabs
.ant-tabs.flamingo-tabs {
  .ant-tabs-nav {
    display: flex;
    align-items: center;
    margin-bottom: -1px;
    .ant-tabs-nav-wrap {
      margin-left: auto;
      flex: none;
    }
  }
  &.ant-tabs-top > .ant-tabs-nav::before {
    border-color: $border-color;
  }
}

//itinerary-form
  //map
  .leaflet-div-icon{
    background-color: transparent !important;
    border: 0 !important;
    color: #EA4335 !important;
    position: relative;
    // &:after{
    //   height: 8px;
    //   width: 8px;
    //   background-color: $white;
    //   content: '';
    //   position: absolute;
    //   left: 8px;
    //   top: 5px;
    //   bottom: 20px;
    //   border-radius: 50rem;
    // }
  }
.itinerary-form {
  .btn-delete {
    color: $danger;
    padding: 0;
    margin: 0;
    line-height: 0;
  }

  .ant-collapse-item > .ant-collapse-header {
    .ant-collapse-expand-icon {
      > .ant-collapse-arrow {
        @include font-size(1.25rem);
        line-height: 1;
        color: $primary;
        border: 1px solid currentColor;
        height: 20px;
        width: 20px;
        border-radius: 50rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        i:before {
          line-height: 0;
        }
      }
    }
  }
  .panel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > span {
      font-weight: 500;
      color: $headings-color;
    }
  }
  .ant-collapse-extra{
    .ant-btn-sm{
      margin-left: 10px;
      border: 1px solid $primary;
      font-weight: 500;
      font-size: 12px;
      text-transform: capitalize;
      color: $primary;
      border-radius: 4px;
      &:hover{
        background-color: $primary;
        color: $white;
      }
    }

  }
}

//ReactModal__Overlay
// .ReactModal__Overlay {
//   z-index: 9999 !important;
// }

//tox-promotion
  .mce-match-marker{
    background-color: #EA4335 !important;
  }
.custom-form{
  .tox-tinymce{
    height: auto !important;
  }
  .tox-promotion{
    display: none;
  }
  .tox-edit-area{
    min-height: 400px;
    overflow-y: auto;
  }
  .tox .tox-menubar+.tox-toolbar-overlord{
    border-top: 1px solid $border-color ;
  }
  .tox:not(.tox-tinymce-inline) .tox-editor-header{
    box-shadow: none;
  }
  .tox .tox-collection--list .tox-collection__item--active{
    background-color: $primary !important;
  }
  .rdp-day_selected, .rdp-day_selected:focus-visible, .rdp-day_selected:hover{
    background-color: $primary;
  }
  .rdp-button:hover:not([disabled]):not(.rdp-day_selected){
    background-color: rgba($primary, 0.1);
  }
}
.tox .tox-collection--list .tox-collection__item--active{
  background-color: $primary !important;
}